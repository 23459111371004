import styled from 'styled-components';
import { font, offset, allBreakpointValue, color, mediaBreakpointUp } from '@/style/mixins';
import { Colors } from '@/style/colors';
import { DTGDatetime as Datetime } from '@/components/blocks/DateTimeGMT/DateTimeGMT.styled';

export const Container = styled.a<{ bgColor?: Colors }>`
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 8px;

    ${offset('m', 'padding')};
    background-color: ${({ bgColor }) => bgColor && color(bgColor)};

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => color(theme.hover)};
            svg {
                fill: ${({ theme }) => color(theme.hover)};
            }
        }
    }

    ._is-ie & {
        &:hover {
            color: ${({ theme }) => color(theme.hover)};
            svg {
                fill: ${({ theme }) => color(theme.hover)};
            }
        }
    }
`;

export const DocumentHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
    }

    & > div {
        ${font('text3-l')};
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        align-self: baseline;
        text-transform: uppercase;
        margin-top: 9px;

        ${mediaBreakpointUp('md')} {
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 0;
        }

        & > svg {
            fill: ${({ theme }) => color(theme.main.background)};
            ${allBreakpointValue('margin-left', 20, 16, 14, 16)};
            margin-right: 6px;

            ${allBreakpointValue('width', 26.5, 22, 17.5, 13, 13)};
            ${allBreakpointValue('height', 30, 25, 20, 15, 15)};

            ${mediaBreakpointUp('md')} {
                margin-right: 0;
            }
        }
    }
`;

export const DocumentTitle = styled.p`
    ${font('text1-l')};
    ${allBreakpointValue('max-width', 933, 745, 660, 480)};
`;

export const DocumentInfo = styled.div`
    display: flex;
    ${allBreakpointValue('margin-top', 10, 6, 4, 6, 8)};

    & > span {
        ${font('text2-l')};
        color: ${color('black', 0.8)};
        ${offset('s', 'margin-left')};
    }

    ${Datetime} {
        span {
            ${font('text2-l')};
            color: ${color('black')};
            opacity: 1;
        }
    }
`;
