import styled from 'styled-components';
import { color, font, hover, mediaBreakpointUp, offset, vw } from '@/style/mixins';

export const Container = styled.label<{ hide?: boolean }>`
    position: relative;
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    align-items: center;
    width: auto;
    cursor: pointer;

    ${({ theme }) =>
        hover(`
            span {
                border-color: ${color(theme.hover)};
            }
        `)};

    p {
        ${font('text2-l')};

        span {
            ${font('text2-b')};
        }
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        cursor: pointer;

        &:checked ~ span {
            background-color: ${({ theme }) => theme && color(theme.hover)};
            border-color: ${({ theme }) => theme && color(theme.hover)};
        }
    }

    & > span {
        min-width: 16px;
        height: 16px;
        margin-right: 20px;
        background-color: transparent;
        border: 1px solid ${color('black', 0.15)};
        border-radius: 2px;
        transition: background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;

        ${mediaBreakpointUp('sm')} {
            margin-right: 8px;
        }

        ${mediaBreakpointUp('xl')} {
            min-width: 14px;
            height: 14px;
        }

        ${mediaBreakpointUp('xxl')} {
            min-width: 16px;
            height: 16px;
        }

        ${mediaBreakpointUp('fhd')} {
            min-width: ${vw(16)};
            height: ${vw(16)};
            margin-right: ${vw(10)};
        }
    }

    &.itemForm {
        align-items: flex-start;
        &:not(:first-of-type) {
            ${offset('xxs', 'margin-top')};
        }
        p {
            margin-top: 0;
        }
    }

    &._error {
        span {
            border-color: ${color('red')};
        }
    }
`;
