import React, { ForwardedRef } from 'react';
import { Container } from './Checkbox.styled';

interface CheckboxProps {
    name: string;
    value: string | number;
    title: string | JSX.Element;
    children?: React.ReactNode;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean | null;
    hide?: boolean;
    disabled?: boolean;
}

const Checkbox = React.forwardRef(
    (
        { name, value, title, onChange, children, className, checked = null, hide = false, disabled }: CheckboxProps,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        return (
            <Container className={className} hide={hide}>
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    ref={ref}
                    onChange={onChange}
                    checked={checked !== null ? checked : undefined}
                    disabled={disabled}
                />
                <span />
                <p>{title}</p>
                {children}
            </Container>
        );
    }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
