import styled, { css } from 'styled-components';
import { color, font, offset, allBreakpointValue, mediaBreakpointUp, vw, hover } from '@/style/mixins';
import { BaseOutsideOffsetsUI } from '@/interfaces';
import { InputFileProps } from './InputFile';

export const Container = styled.div<BaseOutsideOffsetsUI & Pick<InputFileProps, 'isField'>>`
    ${({ outsideTOffset }) => outsideTOffset && offset(outsideTOffset, 'margin-top')};
    ${({ outsideBOffset }) => outsideBOffset && offset(outsideBOffset, 'margin-bottom')};

    ${({ isField }) =>
        isField &&
        css`
            width: 100%;
        `}
`;

export const Label = styled.label<Pick<InputFileProps, 'isField'>>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    cursor: pointer;

    ${({ isField }) =>
        isField &&
        css`
            justify-content: center;
            align-items: center;
            border: 1px dashed ${color('black', 0.2)};
            border-radius: 8px;
            padding: 14px 20px;

            ${mediaBreakpointUp('xxl')} {
                padding: 15px 20px;
            }

            ${mediaBreakpointUp('fhd')} {
                padding: 20px;
            }
        `};

    ${({ theme, isField }) => hover(isField ? `color: ${color(theme.hover)};` : '')};

    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
`;

export const LabelText = styled.span`
    display: flex;
    align-items: flex-start;
    ${font('button')};

    &._hidden {
        display: none;
    }

    svg {
        display: block;
        flex-shrink: 0;

        &.iconList {
            margin-right: 8px;
            ${allBreakpointValue('width', 24, 18, 16, 18, 18)};
            ${allBreakpointValue('height', 24, 18, 16, 18, 18)};
        }
    }
`;

export const BtnDelete = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0;
    ${font('text2-l')};
    color: ${color('red2')};
    background-color: transparent;
    transition: color 0.3s;
    cursor: pointer;

    svg {
        display: block;
        pointer-events: none;
        width: 19px;
        height: 19px;
        fill: currentColor;
        transition: fill 0.3s;
    }

    ${hover(`color: red;`)};
`;

export const BtnDeleteAll = styled(BtnDelete)`
    * > & {
        ${offset('xxs', 'margin-top')};
    }
`;

export const FileListItem = styled.div`
    display: flex;
    align-items: center;
    max-width: 300px;
    word-break: break-all;
    ${font('button')};

    ${mediaBreakpointUp('fhd', 1)} {
        max-width: ${vw(300)};
    }

    & > * + * {
        margin-left: 0.5em;
    }
`;

export const FilesContainer = styled.div`
    * + & {
        ${offset('xxs', 'margin-top')};
    }
`;

export const FileList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;

    ${FileListItem} + ${FileListItem} {
        margin-top: 8px;
    }
`;

export const Message = styled.div`
    margin-top: 10px;
    ${font('text3-l')};
    color: ${color('red')};
`;

export const Descr = styled.div`
    margin-top: 10px;
    ${font('text2-l')};
    opacity: 0.5;
`;
