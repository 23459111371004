import styled from 'styled-components';
import {
    allBreakpointValue,
    buttonStyle,
    color,
    font,
    mediaBreakpointDown,
    mediaBreakpointUp,
    offset,
    sideOffsets,
    vw,
    hover
} from '@/style/mixins';
import Picture from '@/components/common/Picture/Picture';
import { BaseOffsetsUI, BaseOutsideOffsetsUI } from '@/interfaces';
import { BasicBackgrounds, Colors } from '@/style/colors';
import { Container as CheckboxConfirm } from '@/components/ui/CheckboxConfirm/CheckboxConfirm.styled';
import variables, { Offsets } from '@/style/variables';
import { Container as Document, DocumentHeader } from '@/components/blocks/Document/Document.styled';
import { Container as InputFile } from '@/components/ui/InputFile/InputFile.styled';
import Spoiler from '@/components/blocks/Spoiler/Spoiler';
import { SpoilerHead, SpoilerHeadBtn, SpoilerBody } from '@/components/blocks/Spoiler/Spoiler.styled';
import { Container as ReCaptcha } from '@/components/ui/ReCaptcha/ReCaptcha.styled';
import { Container as CircularAnimation } from '@/components/blocks/CircularAnimation/CircularAnimation.styled';

export const FormSpoilerClear = styled.div`
    display: flex;
    align-items: stretch;
    margin-left: auto;
    ${font('text2-l')};

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        ${allBreakpointValue('width', 38, 30, 26, 30, 30)};
        ${allBreakpointValue('height', 38, 30, 26, 30, 30)};
        ${font('text2-b')};
        line-height: 1;
        color: ${color('white')};
        background-color: ${color('black')};
        border-radius: 8px;
        transition: background-color 0.4s ease-in-out;
    }

    & ~ ${SpoilerHeadBtn} {
        ${offset('l', 'margin-left')};
    }
`;

export const FormSpoilerClearButton = styled.p`
    display: flex;
    align-items: center;
    ${allBreakpointValue('margin-right', 20, 16, 14, 16, 16)};
    ${font('text2-l')};
    line-height: 1;
    color: ${color('black')};
    cursor: pointer;
    transition: color 0.4s ease-in-out;

    ${({ theme }) =>
        hover(`
            color: ${color(theme.main.background)};
        `)};

    span {
        position: relative;
        display: block;
        ${allBreakpointValue('width', 14, 11, 9, 11, 11)};
        ${allBreakpointValue('height', 14, 11, 9, 11, 11)};
        opacity: 0.5;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.5px;
            height: 100%;
            background-color: currentColor;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
`;

export const FormSpoiler = styled(Spoiler)<BaseOffsetsUI>`
    position: static;
    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${sideOffsets('margin', true)};

    ${SpoilerHead} {
        align-items: center;

        height: 50px;

        ${mediaBreakpointUp('xxl')} {
            height: ${vw(55, 'xxl')};
        }

        ${mediaBreakpointUp('fhd')} {
            height: ${vw(74)};
        }

        ${sideOffsets()};

        p {
            ${font('text1-b')};
        }
    }

    ${SpoilerHeadBtn} {
        ${allBreakpointValue('width', 24, 18, 16, 18, 18)};
        ${allBreakpointValue('height', 24, 18, 16, 18, 18)};
    }

    ${SpoilerBody} {
        background-color: ${color('white')};
        ${sideOffsets()};
    }

    &._error {
        ${SpoilerHead} {
            p {
                color: ${color('red')};
            }
        }
    }
`;

export const FormSpoilerContent = styled.div`
    ${offset('s', 'padding-top')};
`;

export const FormSpoilerWrapper = styled.div<BaseOffsetsUI>`
    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${FormSpoiler} {
        &:first-child {
            ${SpoilerHead} {
                border-top: thin solid ${color('black', 0.1)};
            }
        }

        ${SpoilerHead} {
            border-bottom: thin solid ${color('black', 0.1)};
        }
    }
`;

export const Container = styled.div<BaseOffsetsUI & BaseOutsideOffsetsUI & { backgroundImage?: string; bgColor?: Colors }>`
    position: relative;
    overflow: hidden;

    ${({ sideOffset }) => sideOffset && sideOffsets('padding')}

    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('xxxl', 'padding-top'))};
    ${({ bottomOffset }) => (bottomOffset ? offset(bottomOffset, 'padding-bottom') : offset('xxxl', 'padding-bottom'))};

    ${({ outsideTOffset }) => outsideTOffset && offset(outsideTOffset, 'margin-top')};
    ${({ outsideBOffset }) => outsideBOffset && offset(outsideBOffset, 'margin-bottom')};

    ${({ bgColor }) => bgColor && `background-color: ${color(bgColor, 0.9)}`};

    ${({ backgroundImage }) =>
        backgroundImage &&
        `
            background-image: url(${backgroundImage});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
    `};

    h2 {
        ${font('h4-b')};
    }

    p {
        ${font('text2-l')};

        &.sub-tl {
            ${font('text1-b')};
        }

        &.sub-tl2 {
            ${font('text2-b')};
        }

        &.sub-tl-big {
            ${font('h5-b')};
        }

        &.tl {
            ${font('h4-b')};
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }

    ${InputFile} {
        ${mediaBreakpointUp('md')} {
            max-width: calc(50% - 4px);
        }
    }
`;

export const SuccessMessage = styled.p`
    position: relative;
    ${offset('xl', 'margin-top')};
    ${offset('m', 'margin-bottom')};
    ${font('text1-l')};
`;

export const SendErrorMessage = styled.div`
    ${offset('m', 'margin-top')};
    ${offset('m', 'margin-bottom')};
    ${font('text1-l')};
    color: ${color('red')};
`;

export const DocsWrapper = styled.div<{ bg?: Colors; topOffset?: Offsets; bottomOffset?: Offsets }>`
    display: flex;
    flex-direction: column;
    background-color: ${({ bg }) => (bg ? color(bg) : color('white'))};
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'margin-top') : offset('xl', 'margin-top'))};

    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${allBreakpointValue('width', 482, 385, 343, 385)};

    border-radius: 8px;

    & > p,
    span {
        ${font('text1-l')};
        margin: 0;
        border-bottom: thin solid ${color('black', 0.1)};
        padding: 20px;

        ${mediaBreakpointUp('md')} {
            padding: ${vw(variables.offsets['m'].tablet768, 768)} ${vw(variables.offsets['m'].tablet768, 768)}
                ${vw(variables.offsets['xl'].tablet768, 768)};
        }

        ${mediaBreakpointUp('xl')} {
            padding: ${vw(variables.offsets['m'].desktop1280, 1280)} ${vw(variables.offsets['m'].desktop1280, 1280)}
                ${vw(variables.offsets['xl'].desktop1280, 1280)};
        }

        ${mediaBreakpointUp('xxl')} {
            padding: ${vw(variables.offsets['m'].desktop1440, 1440)} ${vw(variables.offsets['m'].desktop1440, 1920)}
                ${vw(variables.offsets['xl'].desktop1440, 1920)};
        }

        ${mediaBreakpointUp('fhd')} {
            padding: ${vw(variables.offsets['m'].desktop1920, 1920)} ${vw(variables.offsets['m'].desktop1920, 1920)}
                ${vw(variables.offsets['xl'].desktop1920, 1920)};
        }
    }

    & > div {
        ${offset('m', 'padding')};

        ${Document} {
            p {
                max-width: 187px;
                ${allBreakpointValue('max-width', 209, 179, 166)};
                margin: 0;
            }

            ${DocumentHeader} {
                flex-direction: row;
            }

            padding: 0;

            &:not(:first-of-type) {
                margin-top: 15px;
            }
        }
    }
`;

export const Bg = styled(Picture)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
`;

export const HeaderOneCol = styled.div`
    position: relative;

    h2 {
        ${mediaBreakpointUp('xl')} {
            max-width: 50%;
        }
    }
`;

export const HeaderWidthTwoCol = styled.div`
    position: relative;

    ${mediaBreakpointUp('xl')} {
        display: flex;
    }

    h2 {
        max-width: 300px;

        ${mediaBreakpointUp('fhd')} {
            max-width: ${vw(300)};
        }
    }

    p {
        ${allBreakpointValue('width', 753, 591, 525)};

        ${mediaBreakpointDown('xl')} {
            margin-top: 20px;
        }

        ${mediaBreakpointUp('xl')} {
            margin-left: auto;
            margin-top: 0;
        }
    }
`;

export const BtnSubmit = styled.button`
    ${buttonStyle(false, false, 'black', 'white')};
    max-height: 60px;
    min-height: 40px;
    white-space: nowrap;
    flex-shrink: 0;
    align-self: flex-start;

    ${offset('m', 'margin-top')};

    &:disabled,
    &._disabled {
        background-color: ${color('black', 1)};
        pointer-events: none;

        span {
            opacity: 0.4;
        }
    }

    &._loading {
        span {
            opacity: 0;
        }
    }

    ${mediaBreakpointUp('fhd')} {
        ${offset('none', 'margin-top')};
    }

    ${mediaBreakpointUp('qhd')} {
        max-height: ${vw(75, 'qhd')};
    }

    ${CircularAnimation} {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg.spinner .path {
            stroke: white;
        }
    }
`;

export const ContainerSubscribeForm = styled(Container)`
    ${allBreakpointValue('min-height', 582, 445, 401, 367, 641)};

    & > p {
        ${offset('s', 'margin-top')};
    }

    & > h2,
    & > p,
    form {
        width: 100%;
        ${allBreakpointValue('max-width', 510, 381, 340, 340)};
        position: relative;
    }

    form {
        ${offset('xxl', 'margin-top')};

        & > div {
            display: flex;
            align-items: center;
            ${offset('m', 'margin-top')};

            & > p {
                margin-top: 0;
            }
        }

        p {
            width: 55.88%;
            ${font('text3-l')};
            color: ${color('black', 0.5)};

            a {
                transition: color 0.3s;
                ${hover(`
               color: ${color('black', 1)};
            `)}
            }
        }

        .itemForm {
            width: 100%;
        }
    }

    ${BtnSubmit} {
        margin-right: 10px;
        margin-top: 0;
    }
`;

export const FormTwoCol = styled.form<{ topOffset?: Offsets }>`
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'margin-top') : offset('xxl', 'margin-top'))};
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border: none;

    & > .itemForm {
        & + .itemForm {
            ${offset('xxxxs', 'margin-top')};
        }
    }

    .itemForm {
        textarea {
            height: 95px;
            ${allBreakpointValue('height', 130, 104, 94)};
        }
    }
`;

export const Row = styled.div<BaseOffsetsUI>`
    display: flex;
    flex-direction: column;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};

    &:not(:first-of-type) {
        ${({ topOffset }) => !topOffset && offset('xxxxs', 'margin-top')};
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: row;
    }

    .itemForm {
        ${mediaBreakpointDown('xl')} {
            &:not(:first-child) {
                ${offset('xxxxs', 'margin-top')};
            }
        }
    }

    & > ._col {
        display: flex;
        flex-direction: column;

        ${mediaBreakpointUp('xl')} {
            width: calc(50% - 5px);
        }

        & + ._col {
            ${mediaBreakpointDown('xl')} {
                ${offset('xxxxs', 'margin-top')};
            }

            ${mediaBreakpointUp('xl')} {
                ${offset('xxxxs', 'margin-left')};
            }
        }

        & > .itemForm {
            ${mediaBreakpointUp('xl')} {
                &:not(:first-child) {
                    ${offset('xxxxs', 'margin-top')};
                }
            }
        }
    }
`;

export const Bottom = styled.div<{ captchaMarginBot?: boolean; isLines?: boolean }>`
    display: flex;
    flex-wrap: wrap;

    ${offset('m', 'margin-top')};

    ${mediaBreakpointDown('fhd')} {
        flex-direction: column;
        align-items: flex-start;
    }

    ${ReCaptcha} {
        ${offset('l', 'margin-right')};
        ${({ captchaMarginBot }) => captchaMarginBot && offset('m', 'margin-bottom')};

        ${mediaBreakpointDown('md')} {
            margin-bottom: 24px;
        }
    }

    ${({ isLines }) =>
        isLines &&
        `
    flex-direction: column;

    ${CheckboxConfirm} {
        margin-bottom: 30px;
    }
    `};
`;

export const BottomSubmit = styled.div`
    display: flex;
    flex-direction: column;

    ${mediaBreakpointUp('xl')} {
        flex-direction: row;
    }
`;

export const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${offset('l', 'margin-top')};

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
        align-items: flex-start;

        &._reverse {
            flex-direction: row-reverse;

            ${CheckboxConfirm} {
                margin-right: 8px;
            }
        }
    }

    ${CheckboxConfirm} {
        margin-top: 30px;

        ${mediaBreakpointUp('md')} {
            margin: 0;
            max-width: calc(50% - 4px);
        }
    }
`;

export const CheckboxWrapper = styled.div`
    max-height: 320px;
    ${allBreakpointValue('max-height', 902, 520, 400)};
    overflow-y: auto;
`;

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${offset('xl', 'margin-top')};

    ${mediaBreakpointUp('md')} {
        justify-content: space-between;
        flex-direction: row;
    }

    ${BtnSubmit} {
        order: 1;
        ${offset('xl', 'margin-top')};
        width: fit-content;

        ${mediaBreakpointUp('md')} {
            order: 0;
            margin-top: 0;
            margin-right: 20px;
        }
    }
`;

export const StepFormContainer = styled.form<{ bgColor?: BasicBackgrounds }>`
    position: relative;
    ${({ bgColor }) => bgColor && `background-color: ${color(bgColor)};`}

    ${HeaderWidthTwoCol} {
        ${sideOffsets('margin')};
    }

    h2 {
        width: 100%;
        ${font('h4-b')};
    }

    p {
        ${font('text2-l')};
    }
`;

export const StepFormSection = styled.div<BaseOffsetsUI & { line?: boolean }>`
    position: relative;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${({ line }) =>
        line &&
        `border-top: thin solid ${color('black', 0.1)};

    ${offset('l', 'padding-top')}`};
`;

export const Spacer = styled.div<BaseOffsetsUI>`
    position: relative;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};
`;

export const Buttons = styled.div`
    display: flex;

    ${mediaBreakpointUp('md')} {
        ${offset('xs', 'margin-top')};
    }

    ${mediaBreakpointDown('md')} {
        flex-direction: column;
    }

    button {
        ${buttonStyle(false, false, 'black', 'white')};
        max-height: 60px;
        min-height: 40px;
        white-space: nowrap;
        flex-shrink: 0;

        ${offset('m', 'margin-top')};

        &:not(:first-child) {
            ${offset('xxxxs', 'margin-left')};

            ${mediaBreakpointDown('md')} {
                margin-left: 0;
                margin-top: 6px;
            }
        }

        ${mediaBreakpointDown('md')} {
            margin-left: 0;
        }

        &:disabled,
        &._disabled {
            background-color: ${color('black', 1)};
            pointer-events: none;

            span {
                opacity: 0.4;
            }
        }

        &._loading {
            span {
                opacity: 0;
            }
        }

        ${CircularAnimation} {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            svg.spinner .path {
                stroke: white;
            }
        }
    }
`;
