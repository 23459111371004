import styled from 'styled-components';
import { color, font, mediaBreakpointUp, vw } from '@/style/mixins';
import { Colors } from '@/style/colors';

export const DTGDate = styled.span`
    opacity: 0.8;
    ${font('text3-b')};
`;

export const DTGTime = styled.span`
    flex-shrink: 0;
    opacity: 0.8;
    ${font('text3-b')};
`;

export const DTGDatetime = styled.time<{ colorDate?: Colors }>`
    display: flex;
    align-items: baseline;
    color: ${color('black')};

    ${DTGDate}, ${DTGTime} {
        color: ${({ colorDate }) => (colorDate ? color(colorDate) : color('black'))};
    }
`;

export const DTGRight = styled.span`
    position: relative;
    display: flex;
    align-items: baseline;
    margin-left: 6px;
    padding-left: 6px;

    ${mediaBreakpointUp('xl')} {
        margin-left: 8px;
        padding-left: 8px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-left: 12px;
        padding-left: 12px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-left: ${vw(12)};
        padding-left: ${vw(12)};
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: ${10 / 14}em;
        background-color: ${color('black')};
        opacity: 0.2;
        transform: translateY(-50%);
    }
`;

export const DTGGMT = styled.span`
    flex-shrink: 0;
    opacity: 0.6;
    ${font('text3-l')}
    margin-left: 6px;

    ${mediaBreakpointUp('xl')} {
        margin-left: 8px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-left: 12px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-left: ${vw(12)};
    }
`;
